import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getProductCartQuantity,
    showCurrencycodeSymbol,
    getStock,
    getPersColor,
    getPersFont,
    getPersLocation,
    getPersNote,
    getminQuantity,
    roundedPrice,
    getPriceByColorSizeQty,
    getMoqNote,
    getShowMoqNote,
} from '../../helpers/product'
import { addToCart } from '../../redux/actions/cartActions'
import { addToWishlist } from '../../redux/actions/wishlistActions'
import { addToCompare } from '../../redux/actions/compareActions'
import { AuthContext } from '../../common/Auth'
import { Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { USERTYPES } from '../../constant/userType'
import { X_TENANT_ID } from "../../common/ActionUrl";

import {
    fetchDecorationLocations,
    fetchArtworksById, receiveDecorationLocations,
} from '../../redux/actions/productActions'
import AddDecorationHats from './sub-components/AddDecorationHats'
import Button from '@material-ui/core/Button/Button'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Loader from '../../common/Loader'
import Axios from "axios";

import * as productsAPIUtil from '../../util/products/productsAPIUtil'
import AddnewArtDialogue from '../../components/artwork/AddnewArtDialogue'
import SharedModal from "../../wrappers/modal/SharedModal";
import Select from 'react-select'
import ProductPriceCharges from "./sub-components/ProductPriceCharges";

import {  replaceOrigin } from "../../util/helper";
import { getShowProductPrice } from '../../helpers/product'
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { useProductPriceToShow } from "./state/useProductPriceToShow";
import { useProductArtworkState } from "./state/useProductArtworkState";
import PriceTable from "../../wrappers/product/productPrice/PriceTable";
import ProductImageGalleryHats from "./ProductImageGalleryHats";
import PersonalizationTable from "../../wrappers/product/PersonalizationTable";
import useProductPersonalizationState from "./state/useProductPersonalizationState";
import { Typography } from "@material-ui/core";
import ProductPriceUni from "./sub-components/ProductPriceUni";

const ProductHatsDescriptionInfo = ({
    product,
    artworkList,
    discountedPrice,
    currency,
    finalDiscountedPrice,
    finalProductPrice,
    cartItems,
    wishlistItem,
    compareItem,
    addToast,
    addToCart,
    setToggle,
    toggle,
    setSelectedDecorations,
    addToWishlist,
    addToCompare,
    fetchDecorationLocations,
    fetchArtworksById,
    decorationLocations,
    setSelectedColor,
    selectedColor,
    artworkListById,
    showSellPrice,
    showSell,
    finalProductDiscPrice,
    tempfun,
    setSingleSelectedDecorations,
    singleSelectedDecorations,
    setSelectedImage,
    isReplace,
    setIsReplace,
    ThemeFile,
    setSelectedThumbImgColor,
    selectedDecorations,
    selectedImage,
    selectedThumbImgColor,
    sliderListingData,
    swiperKey,
    colorList,
    decorationLocation,
    setDecorationLocations,
    setDecorationLocationsMain,
    decorationLocationsMain,
    showPrice,
    discountType,
    offerPrice,
}) => {
    const {
        settingData,
        themeSettingsData,
        storeDetails,
        user,
        isDirectToCart,
        userGroupDiscount,
        showAddToCartOnProd,
        decoTypeList,
        userType,
        usePointsAsCurrency,
        ipV4,
        pointValue,
    } = useContext(AuthContext)

    let productPrice = product.price ? product.price : 0
  	let productDiscount = product.discount ? product.discount : 0
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [productDstInventory, setProductDstInventory] = useState(null)
    const [isMoq, setIsMoq] = useState(false)
    const [isSkuMoq, setIsSkuMoq] = useState(false)
    const [isRestrictQty, setIsRestrictQty] = useState(false)
    const [isPriceBreak, setIsPriceBreak] = useState(false)
    const [minAllQty, setMinAllQty] = useState([])
    const [partPrice, setPartPrice] = useState([])
    const [minQty, setMinQty] = useState('1')
    const [prodId, setProdId] = useState(null)
    const [productId, setProductId] = useState(null)
    const [temp, setTemp] = useState(0);
    const [finalPriceProduct, setFinalPriceProduct] = useState(0);
    const [searchTextArtwork, setTextSearchArtwork] = useState('')
    const [isSearchArtwork, setIsSearchArtwork] = useState(false)
    const [customDecorationFee, setCustomDecorationFee] = useState(false)
    const [allowCustomDecoration, setAllowCustomDecoration] = useState(false)
    const [customDecorationPerUnitPrice, setCustomDecorationPerUnitPrice] = useState(null)
    const [customDecorationSetupFee, setCustomDecorationSetupFee] = useState(null)
    const [allowOneDec, setallowOneDec] = useState("0")
    const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
    const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
    const [locationLabel, setLocationLabel] = useState('')
    const [showLocationLabel, setShowLocationLabel] = useState(true)
    const [locationPrompt, setLocationPrompt] = useState('')
    const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
    const [showMoqNote, setShowMoqNote] = useState(false);
    const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
    const [showDecoPopup, setShowDecoPopup] = useState(false);
    const [indexPopup, setIndexPopup] = useState([])
    const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
    const [itemsRowsPopup, setItemsRowsPopup] = useState([])
    const [popupText, setPopupText] = useState('');
    const [artworkLocations, setArtworkLocations] = useState(null);
    const [showColor, setShowColor] = useState(false);
    const [showSize, setShowSize] = useState(false);
    const [loadingArtworks, setLoadingartworks] = useState(false);
    const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
    const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
    const [preventAutoAttach, setPreventAutoAttach] = useState(false)
    const [colorLabel, setColorLabel] = useState('Color')
    const [sizeLabel, setSizeLabel] = useState('Size')
    const [decimalsToShow, setDecimalsToShow] = useState(2);
    const [isStockFor, setIsStockFor] = useState(null);
    const [appliedFirstDeco, setAppliedFirstDeco] = useState(false)
    const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
    const [selectedProductColor, setSelectedProductColor] = useState()
    const [showAddnewart, setAddnewart] = useState(false)
    const [addDecorations, setAddDecorations] = useState(false)
    const [selectedProductLocation, setSelectedProductLocation] = useState('')
    const [selectedProductArtwork, setSelectedProductArtwork] = useState('')
    const [tempValueCheck, setTempValueCheck] = useState(0)
    const [tempValueCheckSize, setTempValueCheckSize] = useState(0)
    const [decorationListOrder, setDecorationListOrder] = useState(false);
    const [selectedProductSize, setSelectedProductSize] = useState()
    const [uniqueLocation, setUniqueLocation] = useState('');
    const [isFirstTime, setIsFirstTime] = useState(true)
    const [storeArtwork, setStoreArtwork] = useState([])
    const [priceTables, setPriceTable] = useState([])
    const [qtyDropDown, setQtyDropDown] = useState(false)
    const [qtyValues, setQtyValues] = useState([])
    const [quantityCount, setQuantityCount] = useState(1)
    const [quantityToShow, setQuantityToShow] = useState("1")
    const [isAdded, setIsAdded] = useState(false)
    const [updatePriceTable, setUpdatePriceTable] = useState([]) 
    const [discountedBasePrice, setDiscountedBasePrice] = useState(null)
    const [discountedTotalPrice, setDiscountedTotalPrice] = useState(null)
    const [items, setItems] = useState([]);
    const [personalizationLoading, setPersonalizationLoading] = useState(false)
    const [hideLocationName, setHideLocationName] = useState(null)

    const [selectedProductDecorations, setSelectedProductDecorations] = useState(
        [],
    )

    const productCartQty = getProductCartQuantity(
        cartItems,
        product,
        selectedProductColor,
        selectedProductSize,
    )

    const {
        inventorySettings,
        isStock,
        isAllowoutofStockOrder,
        productSettings,
    } = useThemeSettingsData({ themeSettingsData });

    const {
        priceDisplay,
        itempriceDisplay,
        itemNumber,
        artworkSetting,
        showCompareToggle,
        showWishListToggle,
        sortSizeSetting
    } = useThemeSettings({ settingData })

    const {
        getPointsValue
    } = useProductPriceToShow({ productSettings, product })

    
    const artworksListState = useSelector((state) => state.productData.artworkList);
    const artworksProductState = useSelector((state) => state.productData.artworkListById);
    const artworksFromState = [...artworksListState, ...artworksProductState]
    const productStockState = useSelector((state) => state.productData.productStock);
    
    let itemFromCart = null
    let tempitemFromCart = cartItems?.filter((item) => item.id === product.id)
    if (tempitemFromCart?.[0]) {
        itemFromCart = tempitemFromCart[0]
    }
    
    const {
        checkMinQty,
        orderAction,
        getDecorationCost,
        getSettingStatus,
        getCartItemTotal,
        getLocations,
    } = useProductConfigState({
        cartItems,
        product,
        artworksFromState,
        artworksProductState,
        allowCustomDecoration,
        selectedProductColor,
        selectedProductDecorations,
        selectedProductLocation,
        artworkSetting,
    })

    const {
        addPersonalizationRow,
        deletePersonalizationRow,
        changePersonalizationArray,
        personalizationArray,
        personalizationLabel,
        setPersonalizationArray,
        personalizationCost,
        showPersonalizationCostInCart,
        showPersonalization,
        personalizationRequired,
        addPersonalization,
        deletePersonalization,
    } = useProductPersonalizationState({
        items,
        product,
        productSettings,
        getSettingStatus,
        setLoading: (loading) => setPersonalizationLoading(loading),
    })

    const dispatch = useDispatch()
    
    useEffect(() => {
        if (sortSizeSetting?.length) {
            setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
        }
    }, [sortSizeSetting])

    useEffect(() => {
        setItems([[{
            id: 1,
            color: selectedProductColor,
            size: selectedProductSize,
            quantity: quantityCount,
        }]])
    }, [selectedProductColor, selectedProductSize, quantityCount]);

    useEffect(() => {
        if (product?.preventAutoAttach === true || product?.preventAutoAttach === false) {
            setPreventAutoAttach(product?.preventAutoAttach)
        } else {
            setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
        }
        if (product?.hideLocationName === true || product?.hideLocationName === false) {
            setHideLocationName(product?.hideLocationName)
        } else {
            setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
        }
    }, [product, productSettings])

    const getStoreArtwork = () => {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'X-TENANT-ID': X_TENANT_ID,
            },
        }
        Axios
            .get('/api/v1/artworkdata/', requestOptions)
            .then((res) => {
                if (res.data.error) {
                    addToast(res.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    setStoreArtwork(res.data)
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        if (!!product?.relabelColor) {
            setColorLabel(product.relabelColor)
        }
        if (!!product?.relabelSize) {
            setSizeLabel(product.relabelSize)
        }
    }, [product])

    const resetQuantity = useCallback(() => {
      if (qtyDropDown) return;
      setQuantityCount(1)
      setQuantityToShow("1")
    }, [qtyDropDown])

    useEffect(() => {
        if (quantityToShow === "") {
          resetQuantity();
        } else {
            const formattedValue = quantityToShow.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setQuantityToShow(formattedValue);
        }
    }, [quantityCount, quantityToShow])

    const {
        selectDecoration, getDecoPrice
    } = useProductArtworkState({
        artworksFromState,
        getSettingStatus,
        setLoading: (loading) => setLoading(loading)
    })

    useEffect(() => {
        if (artworksFromState?.length && selectedProductColor && !appliedFirstDeco) {
            setTimeout(async () => {
                setAppliedFirstDeco(true)
                await applyFirstColor(colors_final.length === 1 ? colors_final[0] : selectedProductColor)
            }, 1)
        }

    }, [artworksProductState])

    const applyFirstColor = async (color) => {
        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === color && val.isSwapImage === true,)
        let tempDecoArray = []
        if (getImage.length === 0) {
            tempDecoArray = await selectDecoration(color, true)
            setIsReplace(true)
        } else {
            setIsReplace(false)
        }
        if (tempDecoArray.length) {
            setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
            setSelectedProductDecorations(tempDecoArray)
            setTemp(temp + 1)
        }
    }

    const colorStyles = {
        container: (styles) => ({
            ...styles, textAlign: 'left',
        }),
        input: (styles) => ({
            ...styles, position: "absolute",
            top: "0",
            width: "100%",
            height: "40px",
            left: "0",
            padding: "0",
            margin: "0",
            display: "block",
            '& > input': {
                position: "absolute",
                top: "-4px",
                height: "40px",
                left: "0",
                paddingLeft: "10px !important",
            },
        }),
        singleValue: (styles) => ({
            ...styles,
            top: '3px',
            position: "relative",
        }),
        placeholder: (styles) => ({
            ...styles,
            top: '3px',
            position: "relative",
        }),
        control: (styles) => ({
            ...styles, backgroundColor: 'transparent',
            border: '1px solid #ebebeb',
            color: '#333',
            fontSize: '14px',
            position: "relative",
            borderRadius: 0,
            height: "38px",
            textAlign: "left !important",
            '& > div': {
                height: "100% !important",
                display: "block !important",
                overflow: "unset !important",
            },
        }),
    };

    useEffect(() => {
        if (productStockState) {
            setProductDstInventory(productStockState.dstInventory)
            setProductInventory(productStockState.inventory)
        }
    }, [productStockState])

    useEffect(() => {
        if (artworkSetting) {
            artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
        }
    }, [artworkSetting])

    useEffect(() => {
        getStoreArtwork()
    }, [])

    useEffect(() => {
        if (!!product?.renameLocationNamePrompt) {
            setLocationPrompt(product?.renameLocationNamePrompt)
        } else if (!!productSettings?.renameLocationNamePrompt) {
            setLocationPrompt(productSettings?.renameLocationNamePrompt)
        } else {
            setLocationPrompt('Select Location')
        }

        if (!!product?.renameLocationNameLabel) {
            setLocationLabel(product?.renameLocationNameLabel)
        } else if (!!productSettings?.renameLocationNameLabel) {
            setLocationLabel(productSettings?.renameLocationNameLabel)
        } else {
            setLocationLabel('Select Location')
        }

        if (!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
            setallowOneDec(product?.AllowOneDecorationLimitPro)
        } else {
            setallowOneDec(productSettings?.AllowOneDecorationLimit)
        }

        if (product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
            setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro)
        }
        else {
            setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
        }

        if (product?.showDecorationLocationLabel === false || product?.showDecorationLocationLabel === true) {
            setShowLocationLabel(product?.showDecorationLocationLabel)
        } else {
            setShowLocationLabel(productSettings?.showDecorationLocationLabel)
        }

        if (product?.showDescription === true || product?.showDescription === false) {
            setProductSettingShowDescription(product?.showDescription)
        } else {
            setProductSettingShowDescription(productSettings?.showDescription)
        }

        if (product?.showColorProd === true || product?.showColorProd === false) {
            setShowColor(product?.showColorProd)
        } else {
            setShowColor(productSettings?.showColor)
        }

        if (product?.showSizeProd === true || product?.showSizeProd === false) {
            setShowSize(product?.showSizeProd)
        } else {
            setShowSize(productSettings?.showSize)
        }

        if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
            setShowDecorationCostInCart(product?.showDecorationCostInCart)
        } else {
            setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
        }

        if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
            setShowSetupChargeInCart(product?.showSetupChargeInCart)
        } else {
            setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
        }

        if (product && productSettings && minQty) {
            setMoqNote(getMoqNote(productSettings, product, minQty));
            setShowMoqNote(getShowMoqNote(productSettings, product));
        }

        if (!!product?.decimalsToShow) {
            setDecimalsToShow(Number(product?.decimalsToShow))
        } else if (!!productSettings?.decimalsToShow) {
            setDecimalsToShow(Number(productSettings?.decimalsToShow))
        } else {
            setDecimalsToShow(2);
        }

    }, [product, productSettings, minQty])

    useEffect(() => {
        verifyShowSize();
        verifyShowColor();
    }, [showColor, showSize])

    useEffect(() => {
        getAlertMessageProductDecoration()
        getArtworkLocations()
    }, [])

    const getArtworkLocations = () => {
        productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
            setArtworkLocations(response.data);
        }).catch(error => {
            console.log('error', error);
            addToast('Error while loading data!', {
                appearance: "error",
                autoDismiss: true
            });
        })
    }


    let groups = []
    let colors = []
    let priceTable = []
    let colors_final = []
    let colors_ids = []
    let colors_hex = []
    let colors_idsColor = []
    let priceTableTemp = []
    product?.ProductPartArray?.ProductPart &&
        product.ProductPartArray.ProductPart.forEach((part) => {
            let priceOption = part
            const size = part.ApparelSize.labelSize
            if (!groups[size]) {
                groups[size] = []
            }
            groups[size].push(priceOption)

            const color = part.ColorArray.Color.colorName
            colors_ids[part.partPrice.partId] = color
            colors_idsColor[color] = part.partPrice.partId
            colors_hex[color] = part.ColorArray.Color.hex

            if (!colors[color]) {
                colors[color] = []
            }
        })
    for (let size in groups) {
        priceTableTemp.push({ size: size, priceOptions: groups[size] })
    }

    priceTableTemp = priceTableTemp.sort((a, b) => {
        return defaultSizeForAll.indexOf(a.size.toLowerCase()) - defaultSizeForAll.indexOf(b.size.toLowerCase());
    })
    let priceTableTemp1 = []
    let priceTableTemp2 = []

    priceTableTemp.map((a) => {
        if (defaultSizeForAll.indexOf(a.size.toLowerCase()) !== -1) {
            priceTableTemp1.push(a)
        } else {
            priceTableTemp2.push(a)
        }
    })

    if (priceTableTemp2.length > 0) {
        priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
    }

    priceTableTemp1.map(res => {
        priceTable.push(res)
    })
    for (let color in colors) {
        if (colorList.includes(color)) {
            colors_final.push(color)
        }
    }
    const numericSort = (a, b) => {
        const aNumeric = parseInt(a);
        const bNumeric = parseInt(b);
        return aNumeric - bNumeric;
    };
    colors_final = colors_final.sort(numericSort);

    if (!selectedProductLocation && decorationLocation?.length === 1) {
        let locationCount = artworkListById.filter(
            (val) =>
                val.artworkLocation == decorationLocation?.[0]?.locationName,
        )

        if (locationCount > 1) {
        } else {
            setSelectedProductLocation(decorationLocation?.[0]?.id);
            setUniqueLocation(decorationLocation?.[0]?.locationName);
        }
    }
    useEffect(() => {
        if (product) {
            if (product.WebstoreProductPartArray?.length) {
                const partPriceArray = product.WebstoreProductPartArray.find(x =>
                    x.active &&
                    (selectedColor && selectedColor !== colorLabel ? x.color === selectedColor : x.color === colors_final[0]) &&
                    (selectedProductSize && selectedProductSize !== sizeLabel ? x.size === selectedProductSize : true)
                );
                if (partPriceArray) {
                    const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
                    setPriceTable(priceTable)
                    setQtyValues(priceTable.map(x => x.minQuantity))
                }
            } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
                const defaultColor = colors_final?.[0] || '';
                const selectedColorToCompare = selectedColor || defaultColor;
                const selectedSizeToCompare = selectedProductSize || '';
                const part = product.ProductPartArray.ProductPart.find(part => {
                    const colors = part?.ColorArray?.Color?.colorName ?? part.color;
                    const size = part?.ApparelSize?.labelSize ?? part.size;
                    return selectedColorToCompare.toLowerCase() === colors.toLowerCase() &&
                        (selectedSizeToCompare === '' || selectedSizeToCompare === size);
                });
                if (part) {
                    const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
                    setPriceTable(priceTable);
                    setQtyValues(priceTable.map(x => x.minQuantity));
                }
            }
            setQtyDropDown(!!product.qtyDropDown)
        }
    }, [product, selectedColor, selectedProductSize])
    const handleCancel = () => {
        setAddnewart(false)
    }
    const verifyShowSize = () => {
        if (priceTable.length === 1) {
            setSelectedProductSize(priceTable[0].size);
        }
        if (!showSize && priceTable.length > 1) {
            setShowSize(true);
        }
    }
    const verifyShowColor = () => {
        if (colors_final.length === 1) {
            setSelectedColor(colors_final[0]);
            setSelectedProductColor(colors_final[0]);
            setAddDecorations(true)
        }
        if (!showColor && colors_final.length > 1) {
            setShowColor(true);
        }
    }
    const goToCart = () => {
        if (colors_final.length === 1) {
            setSelectedProductColor('');
            setTempValueCheck(0)
            setAddDecorations(true)
            colorselectbox()
        } else {
            setSelectedProductColor('');
            setAddDecorations(false)
        }
        if (priceTable.length === 1) {
            setSelectedProductSize('')
            setTempValueCheckSize(0)
            setAddDecorations(true)
            sizeselectbox()
            if (priceTable.length === 1 && !showSize) {
                setSelectedProductSize(priceTable[0].size);
            }
        } else {
            setSelectedProductSize('')
            setAddDecorations(false)
        }
        resetQuantity()
        setUniqueLocation('')
        setSelectedProductDecorations([]);
        deletePersonalization(0);
        setSelectedProductLocation('')
        localStorage.setItem('redirectUrl', window.location.pathname)
        if (isDirectToCart) {
            history.push('/cart')
        }
    }
    const searchArtworkName = (e) => {
        setTextSearchArtwork(e.target.value)
    }

    useEffect(() => {
        if (product && !!product?.decorationListOrder) {
            setDecorationListOrder(product?.decorationListOrder)
        }
    }, [product])

    useEffect(() => {
        if (!!!product.isStockFor || product.isStockFor === 'globalsetting') {
            setIsStockFor(isStock)
        } else {
            setIsStockFor(product.isStockFor)
        }
    }, [product, isStock])

    const handleAddnewArt = (addedNewartdata) => {
        let artwork = addedNewartdata.newArtData
        fetchArtworksById({
            account_id: storeDetails.accountId,
            product_id: product.id,
            userId: user?.userId || undefined,
            categories: product.ProductCategoryArray?.map(x => x.id) || [],
            restrict: true,
        })
        setAddnewart(false)
        if (artwork.id) {
            setSelectedProductArtwork(artwork.id)
            if (artwork?.design?.variation) {
                if (artwork.design.variation.length === 1) {
                    let variation = artwork.design.variation[0]
                    let tempDecoArray = selectedProductDecorations
                        ? selectedProductDecorations
                        : []
                    let locationArray = decorationLocations?.filter(
                        (locationArrayTemp) => locationArrayTemp?.locationName === artwork?.artworkLocation,
                    )
                    let decoObj = {
                        artworkID: artwork.id,
                        location: locationArray?.[0]?.locationName,
                        locationId: locationArray?.[0]?.id,
                        variationUniqID: variation.design_variation_unique_id,
                    }
                    let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray?.[0]?.id)

                    if (getDecoOfLocation.length === 0) {
                        tempDecoArray.push(decoObj)
                        setSingleSelectedDecorations(decoObj)
                        setSelectedProductDecorations(tempDecoArray)
                    }
                    else if (getDecoOfLocation.length === 1) {
                        let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray?.[0]?.id)
                        tempDecoArray = replaceTempDecoArray
                        tempDecoArray.push(decoObj)
                        setSingleSelectedDecorations(decoObj)
                        setSelectedProductDecorations(tempDecoArray)
                        setSelectedDecorations(tempDecoArray)
                    }
                    setSelectedProductLocation('')
                    setSelectedProductArtwork('')
                    setAppliedFirstDeco(true)
                }
            }
        }
    }
    useEffect(() => {
        if (partPrice && partPrice.length > 0) {
            let allQty = [];
            let minQty = getminQuantity(partPrice)
            setMinQty(minQty)
            allQty = partPrice.map((item) => {
                return Number(item.minQuantity)
            })
            setMinAllQty(allQty)
        }
    }, [partPrice])


    useEffect(() => {
        if (selectedProductDecorations) {

            setSelectedDecorations(selectedProductDecorations)
            setToggle(!toggle)
        }
    }, [selectedProductDecorations.length])
    useEffect(() => {
        let inventoryArray = null
        let stock = null
        if (productInventory) {
            if (productInventory.productId === product.productId) {
                inventoryArray = productInventory?.PartInventoryArray?.PartInventory
            }
        }

        inventoryArray &&
            inventoryArray.map((stockArray) => {
                if (
                    selectedProductColor === stockArray.partColor &&
                    stockArray.labelSize === selectedProductSize
                ) {
                    if (stockArray?.InventoryLocationArray?.InventoryLocation.length > 0) {
                        stockArray.InventoryLocationArray.InventoryLocation.map(
                            (inventoryArray) => {
                                if (inventoryArray?.inventoryLocationQuantity?.Quantity) {
                                    stock =
                                        Number(stock) +
                                        Number(
                                            inventoryArray.inventoryLocationQuantity.Quantity.value,
                                        )
                                }
                            },
                        )
                    }
                }
            })
        let dstStock = 0
        if (
            product?.poType === 'Stock' &&
            (isStockFor === 'distributorStock' || isStockFor === 'both')
        ) {
            productDstInventory &&
                productDstInventory.map((dstStockArray) => {
                    if (dstStockArray.productId === product.productId) {
                        if (
                            selectedProductColor === dstStockArray.color &&
                            dstStockArray.size === selectedProductSize
                        ) {
                            dstStock = Number(dstStockArray.quantity)
                        }
                    }
                })
        }
        product.selectedProductColor = selectedProductColor;
        product.selectedProductSize = selectedProductSize;
        //setFinalPriceProduct(getPriceByColorSize(product));
    }, [selectedProductSize, selectedProductColor, isStockFor])

    useEffect(() => {
        getPriceByColorSizeQty(product, quantityCount,
            selectedProductColor,
            selectedProductSize,
            userGroupDiscount).then(res => {
                setFinalPriceProduct(res.price);
                setDiscountedBasePrice(res?.basePrice)
                setDiscountedTotalPrice(res?.price)
            })
    }, [isPriceBreak, selectedProductSize, selectedProductColor, product, quantityCount, userGroupDiscount])

    const [productInventory, setProductInventory] = useState(null)
    useEffect(() => {
        if (product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
            setIsAllowOutOfStockOrder(product?.AllowOutofStock)
        } else {
            setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
        }
    }, [product, isAllowoutofStockOrder])
    useEffect(() => {
        setPartPrice(
            product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray
                ?.PartPrice ?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray
                ?.PartPrice,
        )
        setIsMoq(product?.isProductMoq)
        setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
        setIsSkuMoq(product?.isProductskuMoq)
        setIsRestrictQty(product?.isRestrictQty)
        setProdId(product?.productId)
        setProductId(product?.id)
        setCustomDecorationFee(product?.customDecorationFee)
        setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice)
        setCustomDecorationSetupFee(product?.customDecorationSetupFee)

        setIsSearchArtwork(product?.searchArtwork)
        let componentMounted = true
        return () => {
            componentMounted = false
        }
    }, [product])

    useEffect(() => {
        if (product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
            setAllowCustomDecoration(product?.allowCustomDecoration)
        } else {
            setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
        }
    }, [productSettings, product])

    const getPriceByColorSize = (product) => {
        let productPrice = 0;
        let partArray = []
        if (product.WebstoreProductPartArray && product.WebstoreProductPartArray[0] && product.WebstoreProductPartArray[0].partPrice) {
            partArray = product.WebstoreProductPartArray
        } else if (product && product.ProductPartArray) {
            if (product.ProductPartArray.ProductPart && product.ProductPartArray.ProductPart.length > 0) {
                partArray = product.ProductPartArray.ProductPart
            }
        }
        if (partArray && partArray.length > 0) {
            partArray.map(partPriceArray => {
                if (partPriceArray.ApparelSize && partPriceArray.ApparelSize.labelSize == product.selectedProductSize) {
                    if (partPriceArray.ColorArray && partPriceArray.ColorArray.Color && partPriceArray.ColorArray.Color.colorName && partPriceArray.ColorArray.Color.colorName == product.selectedProductColor) {
                        if (partPriceArray.partPrice && partPriceArray.partPrice.PartPriceArray && partPriceArray.partPrice.PartPriceArray.PartPrice) {
                            let priceArray = partPriceArray.partPrice.PartPriceArray.PartPrice
                            priceArray.map((price, pKey) => {
                                if (Number(price.minQuantity) <= Number(product.quantity)) {
                                    productPrice = price.salePrice
                                } else if (pKey == 0) {
                                    productPrice = (price.salePrice);
                                }
                            })
                        }
                    }
                }
            })
        }
        return Number(productPrice).toFixed(decimalsToShow);
    }
    const getProductStock = () => {
        return getStock(
            selectedProductSize,
            selectedProductColor,
            productInventory,
            product,
            productDstInventory,
            isStockFor,
        )
    }

    useEffect(() => {
        if (storeDetails?.accountId) {
            setLoadingartworks(true)
            fetchArtworksById({
                account_id: storeDetails.accountId,
                product_id: product.id,
                userId: user?.userId || undefined,
                categories: product.ProductCategoryArray?.map(x => x.id) || [],
                restrict: true,
            }).finally(() => {
                setLoadingartworks(false)
            })
        }
    }, [storeDetails])

    useEffect(() => {
        const locations = getLocations();
        if (locations?.locationIds?.length > 0 && isFirstTime) {
            setIsFirstTime(false)
            fetchDecorationLocations(locations.locationIds)
        }  else if(locations?.locationIds?.length === 0 && isFirstTime) {
            dispatch(
                receiveDecorationLocations({
                        data: []
                    }
                ),
            )
            setIsFirstTime(false)
        }
    }, [product, artworksProductState, selectedProductColor])

    const getDeorationDisplay = (locationId, locationName, tempDecorationLocations) => {
        return <>{getSettingStatus('showDecoration') && (
            <AddDecorationHats
                setSelectedProductArtwork={setSelectedProductArtwork}
                selectedProductArtwork={selectedProductArtwork}
                showPopupText={showPopupText}
                addDecoration={addDecoration}
                setAddnewart={setAddnewart}
                selectedProductLocation={locationId}
                selectedColor={selectedColor}
                selectedProductColor={selectedProductColor}
                setLoading={setLoading}
                locationName={locationName}
                searchTextArtwork={searchTextArtwork}
                artworkSetting={artworkSetting}
                storeArtwork={storeArtwork}
                decorationLocations={decorationLocations}
                ThemeFile={!!ThemeFile ? ThemeFile : ''}
                allowCustomDecoration={allowCustomDecoration}
                selectedDecorationsList={selectedProductDecorations}
                artworkDuplicatesAllowed={productSettings?.artworkDuplicatesAllowed}
                artworkDuplicatesAllowedPro={product?.artworkDuplicatesAllowed}
                tempDecorationLocations={tempDecorationLocations}
                product={product}
                artworkLocations={artworkLocations}
                allowOneDec={allowOneDec}
                isAdded={isAdded}
                setIsAdded={setIsAdded}
                decorationListOrder={decorationListOrder}
                preventAutoAttach={preventAutoAttach}
            />
        )}</>
    }

    const getDecoHtml = () => {
        const locations = getLocations();
        let decoExistLocations = locations.decoExistLocations;
        let yFilter = locations.yFilter
        let filteredX = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName)).sort((a, b) => {
            const locationAIndex = yFilter.indexOf(a.locationName);
            const locationBIndex = yFilter.indexOf(b.locationName);
            if (locationAIndex === -1) return 1;
            if (locationBIndex === -1) return -1;
            return locationAIndex - locationBIndex;
        });

        let tempDecorationLocations = filteredX
        if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
            if (decorationLocationsMain.length !== tempDecorationLocations.length) {
                setDecorationLocationsMain(tempDecorationLocations)
            }
        }

        let newLocation = []
        if (selectedProductDecorations.length > 0 || decorationLocation.length > 0) {
            decorationLocation &&
            decorationLocation.map((val, index) => {
                    newLocation.push(val)
                })
        }
        if (uniqueLocation === '') {
            if (!!(decorationLocation.length === 1) && !!(decorationLocation?.[0]?.locationName)) {
                if (selectedProductDecorations.length === 0) {
                    setSelectedProductLocation(decorationLocation[0].id);
                    setUniqueLocation(decorationLocation[0].locationName);
                }
            }
        }
        return (
            <div className="location-hats-list-details">

                {(getSettingStatus('showDecoration') && isReplace) && (!(selectedProductDecorations.length === 1 && allowOneDec === "1")) && (
                    <>
                        {loadingArtworks && <p className={"ml-3"}>Loading ...</p>}
                        {(newLocation.length > 0 || tempDecorationLocations?.length === 1) && (
                            <>
                                {tempDecorationLocations.length > 1 || (selectedProductDecorations.length > 0 && newLocation.length > 0) ? (
                                    <>
                                        {newLocation?.map((locationArray, key) => {
                                            let resIsReq = selectedProductDecorations.find(res => {
                                                return res?.locationId === locationArray?.id && res?.isRequiredForStore == '1'
                                            })
                                            return (
                                                <div key={key} valzue={locationArray.id} style={{ width:'100%' }} className={`${!!resIsReq ? 'requiredDiv' : ''}`}>
                                                    
                                                <div className="location-hats-details pl-3">
                                                    <div className="title-hats-location">
                                                        <h2 style={{
                                                            fontSize: "15px", marginBottom: " 0px",
                                                            lineHeight: "18px",
                                                            fontWeight: "700"
                                                        }}>{`${!!resIsReq ? 'Required Logo' : 'Optional Logo'}`}</h2>
                                                        {!!!hideLocationName && <p style={{
                                                            fontSize: "13px",
                                                            color: " #000",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontWeight: 600
                                                        }}>{locationArray.locationName}</p>}
                                                    </div>
                                                    {getDeorationDisplay(locationArray.id, locationArray.locationName, tempDecorationLocations)}
                                                    </div>
                                                    
                                                </div>
                                            )
                                        })}</>
                                ) : <>
                                    {!!!hideLocationName && <label>&nbsp;{uniqueLocation}</label>}
                                    {getDeorationDisplay(selectedProductLocation, uniqueLocation, tempDecorationLocations)}
                                </>
                                }
                            </>
                        )}
                    </>
                )}
            </div>)
    }

    const getPrice = () => {
        let toatlPrice = 0
        let totalSetupDecoCharges = 0
        let totalDecoCharges = 0
        let totalSetupCharge = 0
        let setupChargeName = 'Setup Charge'
        let setupCharges = []

        let totalAdditionalCharges = 0
        const additionalCharges = []

        if (selectedProductDecorations.length > 0 && getSettingStatus('showDecoration')) {
            selectedProductDecorations.map((decoration, index) => {
                let artwork = null
                if (artworksFromState?.length) {
                    artwork = artworksFromState.find(
                        (artworkArray) => (artworkArray.id === decoration.artworkID && artworkArray.artworkLocation === decoration.location),
                    )

                    if (!!!artwork && artworksProductState?.length) {
                        artwork = artworksProductState.find((artworkArray) =>
                            artworkArray?.productToDecorationMapping?.some(
                                (res) => res.locationName === decoration.location && artworkArray.id === decoration.artworkID
                            )
                        );
                    }
                    if (artwork) {
                        artwork.productToDecorationMapping.filter(x => x.locationName === decoration.location).forEach(productMap => {
                            productMap.charges.forEach(charge => {
                                let price = 0
                                charge.ChargePriceArray.ChargePrice.forEach(x => {
                                    if(x.xMinQty <= quantityCount) price = x.salePrice
                                })
                                const chargePrice = charge.matchOrderQty === "1" ? Number(price) * Number(quantityCount) :  Number(price)
                                additionalCharges.push({
                                    totalSetupCharge: chargePrice,
                                    setupChargeName: charge.chargeName,
                                    chargeCode: charge.chargeCode,
                                })
                                totalAdditionalCharges =
                                    Number(totalAdditionalCharges) + chargePrice
                            })
                        })
                    }
                }
            })
        }

        if (showSetupChargeInCart) {
            if (product?.ChargeArray?.Charge?.length > 0) {
                product.ChargeArray.Charge.forEach(charge => {
                    if (setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
                        const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantityCount)
                        if(chargeByQuantity?.length > 0) {
                            let totalSetupChargeUnit = Number(chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * Number(charge?.matchOrderQty === "1" ? quantityCount : 1);
                            if (totalSetupChargeUnit > 0) {
                                setupChargeName = charge?.chargeName ?? 'Setup Charge';
                                totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
                                toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
                                setupCharges.push({ totalSetupCharge: totalSetupChargeUnit, setupChargeName, chargeCode: charge.chargeCode })
                            }
                        }
                    }
                })
            }

            additionalCharges.forEach(additionalCharge => {
                if (additionalCharge?.totalSetupCharge) {
                    setupCharges.push(additionalCharge)
                }
            })

            totalSetupCharge = totalSetupCharge + totalAdditionalCharges
        }
        let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, toatlPrice, customDecorationSetupFee, allowCustomDecoration)
        toatlPrice = valueForDecorationCost.toatlPrice;
        totalDecoCharges = valueForDecorationCost.totalDecoCharges;
        totalSetupDecoCharges = valueForDecorationCost.totalSetupDecoCharges;
        let rPrice = Number(finalDiscountedPrice) > 0 ? Number(finalDiscountedPrice) : Number(finalPriceProduct !== '0.00' ? finalPriceProduct : finalProductPrice)
        let mainPersonalizationCost = 0
        if (getSettingStatus('showPersonalization') && !showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price) {
            let personalizationCost2 = (quantityCount > 0 ? Number(personalizationCost.price).toFixed(decimalsToShow) : 0)
            if (personalizationCost.matchOrderQty == '1' && !showPersonalizationCostInCart && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                mainPersonalizationCost = Number(personalizationCost2) * Number(quantityCount)
            } else {
                rPrice = Number(rPrice) + Number(personalizationCost2)
            }
        }
        let mainPrice = (Number(rPrice) * Number(quantityCount))
        if (product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
            mainPrice = rPrice + mainPersonalizationCost
        }
        toatlPrice = (mainPrice + Number(showSetupChargeInCart ? totalSetupDecoCharges : 0) + Number(totalDecoCharges) + Number(totalSetupCharge))

        if (personalizationCost?.price !== '' && personalizationCost?.price !== 'undefined' && personalizationCost?.price !== undefined) {
            if (personalizationCost.matchOrderQty == '0') {
                toatlPrice = Number(toatlPrice) + Number(personalizationCost.price) + Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0)
            } else if (personalizationCost.matchOrderQty == '1' && showPersonalizationCostInCart) {
                toatlPrice =
                    Number(toatlPrice) +
                    Number(personalizationCost.price) * Number(quantityCount) + (Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0))
            } else if (personalizationCost.matchOrderQty == '1' && showSetupChargeInCart) {

                toatlPrice = Number(toatlPrice) + (Number(personalizationCost.setupPrice))
            }
        }
        return (<div>
            {
                showPrice !== false && <ProductPriceCharges
                    product={product}
                    decimalsToShow={decimalsToShow}
                    personalizationCost={personalizationCost}
                    showPersonalizationCostInCart={showPersonalizationCostInCart}
                    showSetupChargeInCart={showSetupChargeInCart}
                    quantityCount={quantityCount}
                    showDecorationCostInCart={showDecorationCostInCart}
                    totalDecoCharges={totalDecoCharges}
                    totalSetupDecoCharges={totalSetupDecoCharges}
                    usePointsAsCurrency={usePointsAsCurrency}
                    setupCharges={setupCharges}
                    totalSetupCharge={totalSetupCharge}
                />
            }
            {showPrice !== false && toatlPrice > 0 && <>
                <label style={{ display: 'flex' }}>
                    <b>Total:</b>{usePointsAsCurrency ? getPointsValue(toatlPrice) : <>
                        <p>{`${showCurrencycodeSymbol(currency)}${productSettings?.priceRoundUp ? roundedPrice(Number(toatlPrice), decimalsToShow) : Number(toatlPrice).toFixed(decimalsToShow)}`}</p>
                    </>
                    }</label>
            </>}
        </div>)
    }

    useEffect(() => {
        if (singleSelectedDecorations) {
            let getIndex = product.LogoInfo.filter(
                (val) =>
                    val.color === selectedColor &&
                    val.location === singleSelectedDecorations.location,
            )

            if (getIndex.length > 0) {
                setSelectedImage(getIndex[0]?.image)
            } else {

                let getIndexOfExtra_logo = []
                product.LogoInfo.map(val => {
                    if (val.color === selectedColor) {

                        if (val.extra_logo.filter(e => e.location === singleSelectedDecorations.location).length > 0) {
                            getIndexOfExtra_logo.push(val)
                        }
                    }
                })
                if (getIndexOfExtra_logo.length) {
                    setSelectedImage(getIndexOfExtra_logo[0]?.image)
                } else {
                    let getImgColorIndex = product.LogoInfo.filter(
                        (val) =>
                            val.color === selectedColor
                    )

                    if (getImgColorIndex.length > 0) {
                        setSelectedImage(getImgColorIndex[0]?.image)
                    }
                }

            }
        }

    }, [singleSelectedDecorations])

    const changeColor = async (e) => {
        let color = e && e.target && e.target.value && e.target.value ? e.target.value : e
        if (color == `Select ${colorLabel}`) {
            return false
        }
        setLoading(true)
        setSelectedProductColor(color);
        if (setSelectedColor) {
            setSelectedColor(color)
        }
        setAddDecorations(true)
        setSelectedProductDecorations([])
        setSingleSelectedDecorations(null)

        let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === color && val.isSwapImage === true,)
        if (getImage.length > 0) {
            setSelectedImage(getImage[0]?.image)
        } else {
            let getIndex = product.MediaContent.filter(
                (val) => val.color === color && val.primary,
            )
            if (getIndex.length) {
                setSelectedImage(getIndex[0].url)
            } else {
                let secondarygetIndex = product.MediaContent.filter(
                    (val) => val.color === color,
                )
                setSelectedImage(secondarygetIndex[0]?.url)
            }
        }
        let tempDecoArray = [];
        if (getImage.length === 0) {
            tempDecoArray = await selectDecoration(color, true)
            setIsReplace(true)
        } else {
            setIsReplace(false)
        }
        setLoading(false)

        setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
        setSelectedProductDecorations(tempDecoArray)
        setTemp(temp + 1)
    }

    useEffect(() => {
        if (selectedProductColor) {
            let grp = []
            let updatePriceTable1 = []
            if (product?.WebstoreProductPartArray && product?.WebstoreProductPartArray.length > 0) {
                product?.WebstoreProductPartArray && product.WebstoreProductPartArray.forEach((part) => {
                    if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
                        let priceOption = part
                        const size = part.ApparelSize.labelSize
                        if (!grp[size]) {
                            grp[size] = []
                        }
                        grp[size].push(priceOption)
                    }

                })
            } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
                product?.ProductPartArray?.ProductPart &&
                    product.ProductPartArray.ProductPart.forEach((part) => {
                        if (selectedProductColor === part?.ColorArray?.Color?.colorName) {
                            let priceOption = part
                            const size = part.ApparelSize.labelSize
                            if (!grp[size]) {
                                grp[size] = []
                            }
                            grp[size].push(priceOption)
                        }

                    })
            }
            for (let size in grp) {
                updatePriceTable1.push({ size: size, priceOptions: grp[size] })
            }
            updatePriceTable1 = updatePriceTable1.sort((a, b) => {
                let aValue = defaultSizeForAll.indexOf(a.size.toLocaleLowerCase());
                let bValue = defaultSizeForAll.indexOf(b.size.toLocaleLowerCase());
                if (aValue === -1) aValue = Infinity;
                if (bValue === -1) bValue = Infinity;
                return aValue - bValue;
            })
            setUpdatePriceTable(updatePriceTable1)

        }
    }, [selectedProductColor])

    const colorselectbox = () => {
        let colors_final_temp = colors_final
        if (colors_final_temp.length === 1 && tempValueCheck === 0) {
            changeColor(colors_final_temp[0])
            setSelectedThumbImgColor(colors_final_temp[0])
            setTempValueCheck(tempValueCheck + 1)
        }
        return (
            <select
                onChange={(e) => {
                    changeColor(e)
                }}
                value={selectedProductColor}
            >
                <option key={'colorkey'}>Select {colorLabel}</option>
                {colors_final_temp.map((color, key) => {
                    return (
                        <option key={color} value={color}>
                            {color}
                        </option>
                    )
                })}
            </select>
        )
    }

    const sizeselectbox = () => {
        if ((!(!!selectedProductColor) || selectedProductColor == `Select ${colorLabel}` || showColor === false) && priceTable.length === 1 && tempValueCheckSize === 0) {
            setSelectedProductSize(priceTable[0].size)
            setTempValueCheckSize(tempValueCheckSize + 1)
        }
        return (
            <select
                onChange={(e) => {
                    setSelectedProductSize(e.target.value);
                }}
                value={selectedProductSize}
            >
                <option key={'colorkey'}>Select {sizeLabel}</option>
                {(selectedProductColor == undefined || selectedProductColor == "" || selectedProductColor == `Select ${colorLabel}`) && priceTable.map((tableArray, key) => {
                    return (
                        <option key={key} value={tableArray.size}>
                            {tableArray.size}
                        </option>
                    )
                })}
                {(selectedProductColor !== undefined  && selectedProductColor !== "" && selectedProductColor !== `Select ${colorLabel}`) && updatePriceTable.map((tableArray, key) => {
                    return (
                        <option key={key} value={tableArray.size}>
                            {tableArray.size}
                        </option>
                    )
                })}
            </select>
        )
    }

    const addDecoration = async (variation, arkworkId, selectedProductLocationId, color) => {

        let dPrice = await getDecoPrice(arkworkId)
        setTemp(temp + 1)
        let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
        let locationArray = decorationLocations?.filter((locationArray) => locationArray.id === selectedProductLocationId)

        let artwork_selected = artworksFromState.filter(val => val.id === arkworkId)[0]
        let dataselec = null
        if(artwork_selected?.productToDecorationMapping?.length) {
          dataselec = artwork_selected?.productToDecorationMapping.find(res => res.locationName === locationArray[0].locationName)
        }
        let decoPricedetails = []
        if (dPrice?.length > 0) {
            dPrice.map((priceArray) => {
                if (artwork_selected?.design?.designType === priceArray.decoratorType) {
                    decoPricedetails.push(priceArray)
                }
            })
        }
        let decoData = {
            artworkID: arkworkId,
            location: locationArray[0].locationName,
            locationId: locationArray[0].id,
            variationUniqID: variation.design_variation_unique_id,
            decoPricedetails: decoPricedetails,
            supplierDeco: dataselec?.supplierDeco
        }
        if (!!artwork_selected?.productToDecorationMapping?.[0]?.isRequiredForStore) {
            decoData.isRequiredForStore = artwork_selected?.productToDecorationMapping?.[0]?.isRequiredForStore
        }
        let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray[0].id)

        if (getDecoOfLocation.length === 0) {
            tempDecoArray.push(decoData)
            setSingleSelectedDecorations(decoData)
            setSelectedProductDecorations(tempDecoArray)
        }
        else if (getDecoOfLocation.length === 1) {
            let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray[0].id)
            tempDecoArray = replaceTempDecoArray
            tempDecoArray.push(decoData)
            setSingleSelectedDecorations(decoData)
            setSelectedProductDecorations(tempDecoArray)
            setSelectedDecorations(tempDecoArray)
        }

        let getIndex = product.LogoInfo.filter(
            (val) =>
                val.color === color &&
                val.location === locationArray[0].locationName,
        )

        if (getIndex.length > 0) {
            setSelectedImage(getIndex[0]?.image)
        } else {

            let getIndexOfExtra_logo = []
            product.LogoInfo.map(val => {
                if (val.color === color) {

                    if (val.extra_logo.filter(e => e.location === locationArray[0].locationName).length > 0) {
                        getIndexOfExtra_logo.push(val)
                    }
                }
            })

            if (getIndexOfExtra_logo.length) {

                setSelectedImage(getIndexOfExtra_logo[0]?.image)
            } else {
                let getImgColorIndex = product.LogoInfo.filter(
                    (val) =>
                        val.color === color
                )

                if (getImgColorIndex.length > 0) {
                    setSelectedImage(getImgColorIndex[0]?.image)
                }
            }
        }
    }

    const getInventrySettingStatus = (key) => {
        if (product) {
            if (product[key] === false || product[key] === true) {
                return product[key]
            }
        }
        if (inventorySettings) {
            if (inventorySettings[key] === false || inventorySettings[key] === true) {
                return inventorySettings[key]
            }
        }
        return false
    }

    const isSizeValid = () => {
        return !!selectedProductSize && selectedProductSize !== `Select ${sizeLabel}`;
    }

    const isColorValid = () => {
        return !!selectedProductColor && selectedProductColor !== `Select ${colorLabel}`;
    }

    const isShowInventoryEnabled = getInventrySettingStatus('showInventory');

    const handleChange = (num) => {
        const value = num.toString().replace(/\D/g, '')
        if (value === "") {
            setQuantityToShow("");
        } else {
            setQuantityToShow(value);
        }
        setQuantityCount(Number(value) || 1)
    }

    const onClickArtDesign = (vari, locationName, locationId,) => {

        setTemp(temp + 1)
        let getArt = artworksFromState.filter(t => t.design.variation.filter(y => y.design_variation_unique_id == vari.design_variation_unique_id).length > 0);

        if (getArt.length > 0) {
            let locationData = getArt[0].productToDecorationMapping.filter(val => val.locationName === locationName)
            if (locationData.length === 0)
                locationData = decorationLocations?.filter((locationArray) => locationArray.locationName === locationName)

            if (locationData.length > 0) {
                let locationId = !!locationData?.[0].location ? locationData[0].location : locationData[0].id
                setSelectedProductLocation(locationId);
                let decoData = {
                    artworkID: getArt[0].id,
                    location: locationData[0].locationName,
                    locationId: locationId,
                    variationUniqID: vari.design_variation_unique_id
                }
                setSingleSelectedDecorations(decoData)

                let getIndex = product.LogoInfo.filter(
                    (val) =>
                        val.color === selectedColor &&
                        val.location === locationData[0].locationName,
                )

                if (getIndex.length > 0) {

                    setSelectedImage(getIndex[0]?.image)
                } else {

                    const getIndex = product.LogoInfo.filter((element) => element.color === selectedColor && element.extra_logo.some((subElement) => subElement.location === locationData[0].locationName));

                    if (getIndex.length > 0) {
                        setSelectedImage(getIndex[0]?.image)

                    } else {
                        const getIndex = product.LogoInfo.filter(
                            (val) =>
                                val.color === selectedColor
                        )

                        if (getIndex.length > 0) {
                            setSelectedImage(getIndex[0]?.image)
                        }
                    }
                }
                tempfun()
            }

        }
        setTemp(temp + 0)
    }

    const removeDeco = (mainArraykey, itemsRows, index) => {
        if (index !== '' && index !== null) {
            let tempDecoArray = [...selectedProductDecorations];
            tempDecoArray = tempDecoArray.filter((decoArray, keyIndex) => keyIndex !== index,);
            setSelectedProductDecorations(tempDecoArray);
            setTemp(temp + 0)
        } else {
            setSelectedProductDecorations([]);
        }
    }

    const showPopupText = (mainArraykey, itemsRows, index) => {
        removeDeco(mainArraykey, itemsRows, index);
    }

    const getAlertMessageProductDecoration = () => {
        if (product._id) {
            productsAPIUtil.getProductDecorationPopup(product._id).then(response => {
                if (response?.data?.alertMessage) {
                    setPopupText(response?.data?.alertMessage);
                }
            }).catch(error => {
                console.log('error', error);
                addToast('Error while loading data!', {
                    appearance: "error",
                    autoDismiss: true
                });
            })
        }
    }

    const getAdditionalPrice = () => {
        let personalization = (!showPersonalizationCostInCart && personalizationCost && !!personalizationCost?.price && quantityCount > 0) ? Number(personalizationCost.price) : 0
        let valueForDecorationCost = getDecorationCost(selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, 0, customDecorationSetupFee, allowCustomDecoration)
        let decoration = (!showDecorationCostInCart ? valueForDecorationCost.totalDecoCharges : 0) / quantityCount;
        return personalization + decoration;
    }

    return (
        <div className="product-details-content product-hut-discription-info">
            <h1 style={{
                fontSize: '20px', fontWeight: '600px', lineHeight: "30px",
                marginBottom: '10px'
            }}>{product.productName}</h1>
            <p style={{ marginBottom: '10px' }}>
                <span className="boldcolor">Item Number:</span>{' '}
                {itemNumber ? product[itemNumber] : product['productId']}
            </p>
            {(loading || personalizationLoading) && <Loader />}
            {showPrice === false ? (
                ''
            ) : (
                <>
                    {showPrice !== false &&
                        <ProductPriceUni
                            productPrice={ productPrice }
                            usePointsAsCurrency={ usePointsAsCurrency }
                            pointValue={ pointValue }
                            showSellPrice={ showSellPrice }
                            showSell={ showSell }
                            finalProductDiscPrice={ finalPriceProduct }
                            discountType={ discountType }
                            finalDiscountedPrice={ finalDiscountedPrice }
                            productDiscount={ productDiscount }
                            finalProductPrice={ discountedBasePrice }
                            currency={ currency }
                            mainClass={ "product-details-price" }
                            decimalsToShow={decimalsToShow}
                        />
                    }
                </>
            )}
            {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                <PriceTable
                    priceTable={priceTables}
                    product={product}
                    currency={currency}
                    decimalsToShow={decimalsToShow}
                    doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                    isModel={1}
                    userGroupDiscount={userGroupDiscount}
                    showSellPrice={showSellPrice}
                    showSell={showSell}
                    discountType={discountType}
                    offerPrice={offerPrice}
                />}

            {productSettingShowDescription === true && (
                <div className="pro-details-list" style={{wordWrap:'break-word'}}>
                    <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                </div>
            )}
            <div className="pro-details-size-color">
                {(showColor) && (
                    <div className="pro-details-color-wrap">
                        <span>{colorLabel}</span>
                        <div className="pro-details-color-content">{colorselectbox()}</div>
                    </div>
                )}
                {showSize && (
                    <div className="pro-details-size">
                        <span>{sizeLabel}</span>
                        <div className="pro-details-size-content">{sizeselectbox()}</div>
                    </div>
                )}
                {addDecorations && (userType && (userType === USERTYPES.ADMIN || userType === USERTYPES.USER) || productSettings?.allowToUpCustArtwork === "2") && getSettingStatus('showDecoration') && allowCustomDecoration ? <div className="mt-4">
                    <div className="col-md AddNewart ml-3">
                        <Button variant="outlined" color="primary" onClick={() => { setAddnewart && setAddnewart(true) }}>Add New Art</Button>
                    </div>
                </div> : ''}
            </div>
            <div className="row">
                <div className="col-md-12">
                    
                {addDecorations && 
                            <div class="product-hats-details">
                                <div class="small-images"></div>
                                <div class="location-hats-list-details">
                                <div className={`mt-20 ${isSearchArtwork ? 'mb-20' : ''} w-100`}>                        
                                {isSearchArtwork &&
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                className="inputStyle"
                                                value={searchTextArtwork}
                                                name="searchTextArtwork"
                                                placeholder={(product?.artworkSearchPlaceholderText === undefined ? "Search Artwork…" : (!!product?.artworkSearchPlaceholderText ? product.artworkSearchPlaceholderText : ''))}
                                                onChange={(e) => searchArtworkName(e)}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            </div>
                            </div>
                        }
                    <div className="product-hats-details">
                <ProductImageGalleryHats
                    isSearchArtwork={isSearchArtwork}
                    product={product}
                    isSmall={true}
                    swiperKey={swiperKey}
                    sliderListingData={sliderListingData}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    artworkListById={artworkListById}
                    selectedDecorations={selectedDecorations}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    singleSelectedDecorations={singleSelectedDecorations}
                    setSelectedThumbImgColor={setSelectedThumbImgColor}
                    selectedThumbImgColor={selectedThumbImgColor}
                    isReplace={isReplace}
                    setIsReplace={setIsReplace}
                />
                
                    {addDecorations && getDecoHtml()}
                    </div>
                </div>
            </div>

            <div className="pro-details-quality" style={{marginBottom: '0px', marginTop:'0px'}}>
                {addDecorations && getPrice()}
            </div>
            {product.affiliateLink ? (
                <div className="pro-details-quality">
                    <div className="pro-details-cart ml-0">
                        <a
                            href={product.affiliateLink}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Buy Now
                        </a>
                    </div>
                </div>
            ) : (
                <>
                    <div className="pro-details-quality">
                        {qtyDropDown ? <select name="qty-drop-down" id="qty-drop-down"
                            className="cart-plus-minus-box cart-plus-minus"
                            style={{
                                borderWidth: '1px',
                                padding: '5px',
                                textAlign: "center"
                            }}
                            onChange={(e) => setQuantityCount(Number(e.target.value))}
                        >
                            <option value="0">QTY</option>
                            {
                                qtyValues.map((o, index) => {
                                    return (
                                        <option value={o.toString()}>{o}</option>
                                    )
                                })
                            }
                        </select> :
                            <div className="cart-plus-minus">
                                <button
                                    onClick={() => {
                                        let currentCount = Number(quantityCount.toString().replace(/,/g, ''));
                                        let num = currentCount > 1 ? currentCount - 1 : 0;
                                        setQuantityCount(num)
                                        setQuantityToShow(num > 0 ? num.toLocaleString() : ""); // Update the display value
                                    }}
                                    className="dec qtybutton"
                                >
                                    -
                                </button>
                                <input
                                    className="cart-plus-minus-box"
                                    type="text"
                                    value={quantityToShow}
                                    onChange={(e) => handleChange(e.target.value)}
                                    maxLength={6}
                                />
                                <button
                                    onClick={() => {
                                        if (IsAllowOutOfStockOrder) {
                                            handleChange(quantityCount + 1)
                                        } else {
                                            let num1 =
                                            Number(quantityCount.toString().replace(/,/g, '')) <
                                            getProductStock() - productCartQty
                                                ? Number(quantityCount.toString().replace(/,/g, '')) + 1
                                                : Number(quantityCount.toString().replace(/,/g, ''))
                                        handleChange(num1)

                                        }
                                    }}
                                    className="inc qtybutton"
                                >
                                    +
                                </button>
                            </div>}
                        {((showAddToCartOnProd && getSettingStatus('showAddToCart') !== false) || getSettingStatus('showAddToCart') !== false) && (
                            <div className="pro-details-cart">
                                {getProductStock() > 0 || IsAllowOutOfStockOrder || !selectedProductSize || selectedProductSize === `Select ${sizeLabel}`
                                    || !selectedProductColor || selectedProductColor === `Select ${colorLabel}` ? (
                                    <Link
                                        to={{
                                            state: { redirectUrl: window.location.pathname },
                                        }}
                                        onClick={() => {
                                            /*if (product?.showProductPrice !== false) {*/
                                            if (!quantityToShow) {
                                                orderAction("Please enter quantity", "error");
                                                return;
                                            }
                                            if (checkMinQty(prodId, quantityCount, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, { color: selectedProductColor, size: selectedProductSize }, product?.doNotCalculateAtOrder)) {
                                                let blnktest = []
                                                if (!!personalizationArray) {
                                                    blnktest = !!personalizationArray ? personalizationArray.filter(x => x.displayText === '') : []
                                                }
                                                if (showSize) {
                                                    if (!selectedProductSize || selectedProductSize === `Select ${sizeLabel}`) {
                                                        orderAction(`You must select a ${sizeLabel}`, "error");
                                                        return;
                                                    }
                                                }
                                                if (showColor) {
                                                    if (!selectedProductColor || selectedProductColor === `Select ${colorLabel}`) {
                                                        orderAction(`You must select a ${colorLabel}`, "error");
                                                        return;
                                                    }
                                                }
                                                if ((RequiredDecorationForCheckOutPro === true) && !((!!selectedProductDecorations && selectedProductDecorations.length > 0))) {
                                                    orderAction('Please choose a decoration to add this product to your cart', "error")
                                                    return;
                                                }
                                                if (blnktest.length > 0) {
                                                    orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', "error")
                                                    return;
                                                }
                                                const quantityFromCart = getCartItemTotal(prodId, selectedProductColor, selectedProductSize);
                                                const stock = getProductStock();
                                                if (!IsAllowOutOfStockOrder && (stock - quantityFromCart < quantityCount)) {
                                                    resetQuantity()
                                                    orderAction("Stock is not enough to add to cart", "error");
                                                    return;
                                                }
                                                addToCart(
                                                    product,
                                                    addToast,
                                                    Number(quantityCount.toString().replace(/,/g, '')),
                                                    selectedProductColor,
                                                    selectedProductSize,
                                                    selectedProductDecorations,
                                                    personalizationArray,
                                                    (!!personalizationArray && personalizationArray.length > 0 && getSettingStatus('showPersonalization') == true) ? personalizationCost : {},
                                                    userGroupDiscount,
                                                    isPriceBreak,
                                                    false,
                                                    RequiredDecorationForCheckOutPro,
                                                    ipV4,
                                                )
                                                goToCart()
                                            }
                                            /*} else {
                                              addToast('Price not available', {
                                                appearance: "error",
                                                autoDismiss: true
                                              })
                                            }*/

                                        }}
                                        disabled={
                                            IsAllowOutOfStockOrder === true
                                                ? !IsAllowOutOfStockOrder
                                                : getProductStock() === 0
                                                    ? true
                                                    : false
                                        }
                                    >
                                        {' '}
                                        Add To Cart{' '}
                                    </Link>
                                ) : (
                                    <button disabled>Out of Stock</button>
                                )}
                            </div>
                        )}

                        {showWishListToggle && (
                            <div className="pro-details-wishlist ml-10">
                                <button
                                    className={wishlistItem !== undefined ? 'active' : ''}
                                    disabled={wishlistItem !== undefined}
                                    title={
                                        wishlistItem !== undefined
                                            ? 'Added to wishlist'
                                            : 'Add to wishlist'
                                    }
                                    onClick={() => addToWishlist(product, addToast)}
                                >
                                    <i className="pe-7s-like" />
                                </button>
                            </div>
                        )}
                        {showCompareToggle && (
                            <div className="pro-details-compare">
                                <button
                                    className={compareItem !== undefined ? 'active' : ''}
                                    disabled={compareItem !== undefined}
                                    title={
                                        compareItem !== undefined
                                            ? 'Added to compare'
                                            : 'Add to compare'
                                    }
                                    onClick={() => addToCompare(product, addToast)}
                                >
                                    <i className="pe-7s-shuffle" />
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

            {showAddnewart && (
                <AddnewArtDialogue
                    productId={productId}
                    open={showAddnewart}
                    show={showAddnewart}
                    title="Add New Artwork"
                    onHide={() => handleCancel()}
                    handleSubmit={handleAddnewArt}
                    cancel={() => handleCancel()}
                    catalogueItem={product}
                />
            )}

            {showDecoPopup && (
                <SharedModal
                    indexPopup={indexPopup}
                    mainArrayKeyPopup={mainArrayKeyPopup}
                    itemsRowsPopup={itemsRowsPopup}
                    popupText={popupText}
                    removeDeco={removeDeco}
                    setShowDecoPopup={setShowDecoPopup}
                    showDecoPopup={showDecoPopup}
                />
            )}
            {showPersonalization && !personalizationArray && (
                <Button
                    variant="contained"
                    className="mt-2 mb-2"
                    onClick={() => addPersonalization(0)}
                >Add {personalizationLabel}</Button>
            )}
            {personalizationArray && !personalizationRequired && (
                <Button
                variant="contained"
                className="mt-2 mb-2"
                onClick={() => {
                    deletePersonalization(0)
                }}
                >
                    Delete {personalizationLabel}
                </Button>
            )}
            {personalizationArray && (
                <Typography variant="h6" className={`mt-${personalizationRequired ? '4' : '2'} mb-2`}>
                    {personalizationLabel}
                </Typography>
            )}
            {personalizationArray && (           
                <PersonalizationTable
                    personalizationArray={personalizationArray}
                    setPersonalizationArray={setPersonalizationArray}
                    addPersonalizationRow={addPersonalizationRow}
                    deletePersonalizationRow={deletePersonalizationRow}
                    changePersonalizationArray={changePersonalizationArray}
                    colorLabel={colorLabel}
                    sizeLabel={sizeLabel}
                />
            )}
        </div>
    )
}

ProductHatsDescriptionInfo.propTypes = {
    addToCart: PropTypes.func,
    addToCompare: PropTypes.func,
    addToWishlist: PropTypes.func,
    addToast: PropTypes.func,
    cartItems: PropTypes.array,
    compareItem: PropTypes.array,
    currency: PropTypes.object,
    discountedPrice: PropTypes.number,
    finalDiscountedPrice: PropTypes.number,
    finalProductPrice: PropTypes.number,
    product: PropTypes.object,
    wishlistItem: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (
            item,
            addToast,
            quantityCount,
            selectedProductColor,
            selectedProductSize,
            selectedProductDecorations,
            personalizationArray,
            personalizationCost,
            userGroupDiscount,
            isPriceBreak,
            RequiredDecorationForCheckOutPro,
            ipV4,
        ) => {
            dispatch(
                addToCart(
                    item,
                    addToast,
                    quantityCount,
                    selectedProductColor,
                    selectedProductSize,
                    selectedProductDecorations,
                    personalizationArray,
                    personalizationCost,
                    userGroupDiscount,
                    isPriceBreak,
                    false,
                    RequiredDecorationForCheckOutPro,
                    ipV4
                ),
            )
        },
        addToWishlist: (item, addToast) => {
            dispatch(addToWishlist(item, addToast))
        },
        addToCompare: (item, addToast) => {
            dispatch(addToCompare(item, addToast))
        },
        fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
        fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
    }
}
const mapStateToProps = (state) => {
    return {
        decorationLocations: state.productData.decorationLocations,
        artworkListById: state.productData.artworkListById,
        artworkList: state.productData.artworkList,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductHatsDescriptionInfo);